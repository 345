import React from 'react';

const IconLogo = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="24"
    role="img"
    width="24"
    height="24"
    viewBox="0 0 454 454">
    <title>Logo</title>
    <g>
      <path
        d="M0,0v454h454V0H0z M331.204,345.802H266.4v-86.399h-78.801v86.399h-64.803V108.198h64.803v86.4H266.4v-86.4h64.804V345.802
		L331.204,345.802z"
      />
    </g>
  </svg>
);

export default IconLogo;
