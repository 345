import React from 'react';

const IconReddit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    fill="none"
    stroke="currentColor"
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-reddit"
    viewBox="0 0 56.7 56.7">
    <title>Reddit</title>
    <path d="M53.048,28.4457c0-3.1373-2.5525-5.6899-5.6899-5.6899c-1.3956,0-2.7116,0.5049-3.7506,1.419    c-3.7627-2.4079-8.7834-3.925-14.3215-4.0884l3.3886-11.5903l8.3201,1.5458c-0.0004,0.0676-0.0135,0.1321-0.01,0.2004    c0.101,1.9801,1.7938,3.5086,3.774,3.4076c1.98-0.101,3.5086-1.7939,3.4076-3.7739c-0.101-1.9801-1.7938-3.5086-3.7739-3.4076    c-1.2785,0.0652-2.3605,0.7985-2.9455,1.8398c-0.001-0.0003-0.0019-0.001-0.003-0.0012l-10.026-1.8626l-3.9934,13.6388    c-5.5515,0.148-10.5881,1.6563-14.367,4.0598c-1.0335-0.8944-2.3358-1.3872-3.7156-1.3872c-3.1372,0-5.6899,2.5525-5.6899,5.6899    c0,1.9405,0.999,3.7299,2.6167,4.7739c-0.0983,0.5678-0.1544,1.1443-0.1544,1.7296c0,8.2127,9.9554,14.8948,22.1934,14.8948    S50.501,43.1618,50.501,34.9492c0-0.5684-0.0522-1.1283-0.1451-1.6803C52.0182,32.2329,53.048,30.4167,53.048,28.4457z     M17.7602,32.3183c0-1.9298,1.565-3.4956,3.4975-3.4956c1.9307,0,3.4957,1.5658,3.4957,3.4956    c0,1.9316-1.565,3.4966-3.4957,3.4966C19.3252,35.8149,17.7602,34.2499,17.7602,32.3183z M36.5501,41.7755    c-0.1042,0.109-2.6132,2.6615-8.2833,2.6615c-5.6992,0-7.9791-2.5879-8.073-2.6977c-0.3213-0.3752-0.2775-0.9403,0.0977-1.2605    c0.3733-0.3184,0.9328-0.2774,1.255,0.0921c0.0511,0.0559,1.954,2.0788,6.7204,2.0788c4.8492,0,6.9754-2.0946,6.9968-2.115    c0.3435-0.35,0.9086-0.3584,1.2614-0.0149C36.8759,40.8623,36.889,41.4209,36.5501,41.7755z M35.8547,35.8149    c-1.9326,0-3.4975-1.565-3.4975-3.4966c0-1.9298,1.565-3.4956,3.4975-3.4956c1.9308,0,3.4957,1.5658,3.4957,3.4956    C39.3504,34.2499,37.7855,35.8149,35.8547,35.8149z" />
  </svg>
);

export default IconReddit;
